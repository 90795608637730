import {
  createStore, createEvent,
} from 'effector'

// Определения

export const widthChanged = createEvent('widthChanged')

const $currentWidth = createStore('')

// Логика и связи

$currentWidth
  .on(widthChanged, (state, width) => width)

export default $currentWidth