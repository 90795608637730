/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { IconSvg } from '@sivasifr/icons/IconSvg'

const Social = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          social {
            instagram
            vkontakte
            tiktok
            facebook
            youtube
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      {
        Object.entries(social).map(([key, val]) => (
          <a
            href={val}
            key={key}
            className="d-flex"
          >
            <IconSvg name={key} />
          </a>
        ))
      }
    </div>
  )
}

export default Social
