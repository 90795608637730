import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import { AppBar } from '@sivasifr/ui-core/AppBar'
import { List, ListItem } from '@sivasifr/ui-core/List'
import { IconButton } from '@sivasifr/ui-core/IconButton'
import { Drawer } from '@sivasifr/ui-core/Drawer'
import { IconSvg } from '@sivasifr/icons/IconSvg'
import { useMeasure } from "react-use"
import clsx from 'clsx'
import Social from "../Social"
import { widthChanged } from "./model"

const Menu = ({taxons, isMobile}) => (
  <List className={clsx(
    "nav-main",
    {
      'flex-column': isMobile,
      'm-3': isMobile
    }
  )}>
    {taxons.map(({id, label}) => (
      <ListItem
        key={id}
        button
        className={clsx(
          "mr-3",
          {'my-2': isMobile}
        )}
        component={Link}
        to={`/taxa/${id}`}
      >
        {label}
      </ListItem>
    ))}
  </List>
)

const Layout = ({ location, children }) => {
  const [open, setOpen] = React.useState(false)
  const [ref, { width }] = useMeasure()

  React.useEffect(() => {
    widthChanged(width)
  }, [width])

  const data = useStaticQuery(graphql`
    query navQuery {
      allTaxon(sort: {order: ASC, fields: [order]}) {
        nodes {
          id
          label
        }
      }
    }
  `)

  const taxons = data.allTaxon.nodes
  const handlerToggle = (e) => {
    setOpen(!open)
  }
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const isMobile = width < 992

  return (
    <div
      className="root"
      data-is-root-path={isRootPath}
      ref={ref}
    >
      <AppBar>
        <div className="appbar">
          <div className="logo mx-3">
            <Link to="/">
              <img
                src="/site-logo.svg"
                alt="Логотип Евгений Иванников"
                width={40}
                height={40}
              />
            </Link>
          </div>
          <div className="menu">
            {
              !isMobile && taxons != null && (
                <Menu taxons={taxons} />
              )
            }
            {
              isMobile && (
                <IconButton onClick={handlerToggle}>
                  <IconSvg name="bars" />
                </IconButton>
              )
            }
          </div>
          <div className="sotial"><Social /></div>
        </div>
      </AppBar>
      {
        isMobile && taxons != null && (
          <Drawer anchor="left" open={open} onClose={handlerToggle}>
            <Menu taxons={taxons} isMobile={isMobile} />
          </Drawer>
        )
      }
      <main>{children}</main>
      <footer className="d-flex justify-content-center p-3">
        © Евгений Иванников, {new Date().getFullYear()}. При использовании материалов сайта ссылка обязательна.
      </footer>
    </div>
  )
}

export default Layout
